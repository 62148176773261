@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.1em;
  color: #333;
  font-style: normal;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0 20px;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 100px;
}

@media only screen and (max-width: 834px) {
  #contents_wrap {
    margin-bottom: 50px;
  }
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

#header {
  width: 100%;
}

#header .hedwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

@media only screen and (max-width: 640px) {
  #header .hedwrap {
    display: block;
    padding: 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .hedwrap {
    align-items: center;
    padding: 10px;
  }
}

#header #siteID {
  width: 17%;
}

@media only screen and (max-width: 640px) {
  #header #siteID {
    padding: 10px 0 !important;
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header #siteID {
    width: 35%;
    margin-left: 50px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1300px) {
  #header #siteID {
    width: 24%;
  }
}

#header #siteID a {
  opacity: 1;
}

#header .tel2 {
  font-size: 26px;
  color: #fff;
  background: #aa4a92;
  border-radius: 50px;
  padding: 15px;
  margin-right: 15px;
}

#header .tel2 i {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  #header .tel2 i {
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  #header .tel2 span {
    display: none;
  }
  #header .tel2 a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .tel2 {
    font-size: 20px;
  }
}

#hed_info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
}

@media only screen and (max-width: 640px) {
  #hed_info {
    width: 100%;
    padding: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info {
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 1300px) {
  #hed_info {
    display: block;
    width: 63%;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #hed_info {
    width: 67%;
  }
}

#hed_info .time {
  font-size: 13px;
  text-align: left;
  margin-right: 15px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info .time {
    text-align: right;
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  #hed_info .time {
    margin-right: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1300px) {
  #hed_info .time {
    text-align: right;
  }
}

#hed_info .cont i {
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  #hed_info .cont i {
    margin-right: 0;
  }
}

#hed_info .cont a {
  padding: 15px;
  display: block;
  background: #aa4a92;
  border-radius: 50px;
  font-size: 22px;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  #hed_info .cont a span {
    display: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info .cont a span {
    display: none;
  }
}

#hed_info .hed_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  #hed_info .hed_cont {
    padding: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info .hed_cont {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 834px) and (max-width: 1300px) {
  #hed_info .hed_cont {
    margin-top: 15px;
    justify-content: flex-end;
  }
}

#nav_global {
  padding: 10px 0;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  #nav_global ul {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  #nav_global ul li {
    text-align: left;
    padding-left: 20px;
    border-bottom: 1px solid #aa4a92;
  }
}

#nav_global ul li a {
  color: #333;
  display: block;
}

#nav_global ul li a:hover {
  opacity: 0.6;
}

#mainArea {
  width: 100%;
}

#mainArea img {
  width: 100% !important;
  height: auto;
}

@media only screen and (max-width: 640px) {
  #mainArea img {
    width: 120% !important;
    min-height: 60vw !important;
    margin: 0 -20% !important;
    object-fit: cover;
  }
}

.mainwrap {
  position: relative;
}

.mainwrap .main_txt {
  position: absolute;
  top: 30%;
  left: 10%;
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_txt {
    top: 22%;
    left: 0;
    width: 80% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap .main_txt {
    width: 50%;
  }
}

.slick-initialized .slick-slide {
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-position: left top;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-align: left;
  text-shadow: 5px 5px 10px rgba(76, 120, 232, 0.9);
  text-align: center;
  font-family: source-han-serif-japanese, serif;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 1.7em;
  }
}

#local-keyvisual h1 span {
  display: block;
  padding: 100px 0;
  background-color: rgba(76, 120, 232, 0.2);
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.pan1 {
  margin: 20px 0;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.mean-container .mean-bar {
  background: #b94047 !important;
  position: fixed !important;
  z-index: 8 !important;
}

.mean-container .mean-nav {
  background: #b94047 !important;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid #fff !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  /*background-color: #e9832f !important;*/
}

.mean-container .mean-nav ul li a {
  padding: 10px 5% !important;
}

.mean-container .mean-nav ul li a i {
  margin-right: 15px;
  font-size: 16px;
}

.drawer-hamburger {
  z-index: 101 !important;
}

.drawer-hamburger span {
  background: #333 !important;
}

.drawer-hamburger span:before, .drawer-hamburger span:after {
  background: #333 !important;
}

.drawer-hamburger.change-color span {
  background: #2e4121 !important;
}

.drawer-hamburger.change-color span:before, .drawer-hamburger.change-color span:after {
  background: #2e4121 !important;
}

.drawer-nav {
  z-index: 101 !important;
}

.drawer-nav ul li:first-child {
  background: #fff;
  padding: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.drawer-nav ul li a {
  display: block;
  padding: 1em 0;
  color: #fff;
  font-size: 20px;
}

.drawer-hamburger {
  z-index: 201 !important;
}

.drawer-overlay {
  z-index: 201 !important;
}

.drawer-nav {
  z-index: 202 !important;
}

.drawer-open .drawer-hamburger .drawer-hamburger-icon {
  background-color: transparent !important;
}

.drawer-nav ul li a {
  display: block;
  padding: 1em 0;
  color: #fff;
  font-size: 18px;
}

@media only screen and (max-width: 834px) {
  .for-pc {
    display: none !important;
  }
}

.not-for-pc {
  display: none !important;
}

@media only screen and (max-width: 834px) {
  .not-for-pc {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .not-for-sp {
    display: none !important;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: underline;
}

footer#global_footer .inner {
  padding: 50px 0 0;
}

footer#global_footer #nav_footer {
  display: flex;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer li {
  text-align: left;
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 10px;
  display: block;
  color: #333;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy {
  color: #fff;
}

footer#global_footer #copy a {
  color: #fff !important;
}

footer#global_footer .smallbg {
  background: #7fbfff;
}

#footer {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  #footer {
    display: block;
  }
}

#footer .ft_right {
  width: 26%;
}

@media only screen and (max-width: 640px) {
  #footer .ft_right {
    width: 100% !important;
    margin-top: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer .ft_right {
    width: 40%;
  }
}

#footer .ft_right .ft_map iframe {
  width: 100%;
  height: 340px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer .ft_right .ft_map iframe {
    height: 200px;
  }
}

#footer .ft_center {
  width: 30%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer .ft_center {
    display: none;
  }
}

#footer .ft_left {
  width: 40%;
  text-align: left;
}

@media only screen and (max-width: 640px) {
  #footer .ft_left {
    width: 100% !important;
    text-align: center;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer .ft_left {
    width: 67%;
  }
}

#footer .ft_left ul {
  line-height: 2;
}

.totop {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

/* box */
.h2_01 h2 {
  font-size: 30px;
  line-height: 1.5;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #16569e;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 20px;
  }
}

.h2_02 h2 {
  position: relative;
  display: inline-block;
  padding: 0 55px;
  font-size: 30px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #16569e;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 20px;
  }
}

.h2_02 h2:before, .h2_02 h2:after {
  content: '';
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 230px;
  height: 1px;
  background-color: #16569e;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2:before, .h2_02 h2:after {
    width: 180px;
  }
}

.h2_02 h2:before {
  left: -30%;
}

.h2_02 h2:after {
  right: -30%;
}

.h2_03 h2 {
  font-size: 30px;
  color: #16569e;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
}

@media only screen and (max-width: 834px) {
  .h2_03 h2 {
    font-size: 20px;
  }
}

.h2_03 h2:before {
  content: '';
  position: absolute;
  bottom: -15px;
  display: inline-block;
  width: 60px;
  height: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #aa4a92;
  border-radius: 2px;
}

.btn a {
  padding: 15px;
  display: block;
  background: #aa4a92;
  color: #fff;
  border-radius: 50px;
}

.btn i {
  margin-left: 7px;
}

.txt01 {
  line-height: 3;
}

.txt01 span {
  position: relative;
  padding-left: 1.8em;
  /*アイコン分のスペース*/
  line-height: 1.4;
  font-size: 20px;
  display: inline-block;
}

.txt01 span:before {
  font-family: "Font Awesome 5 pro";
  content: "\f14a";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 30px;
  /*サイズ*/
  left: 0;
  /*アイコンの位置*/
  top: -8px;
  /*アイコンの位置*/
  color: #5ab9ff;
  /*アイコン色*/
}

.sec_01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_01 {
    display: block;
  }
  .sec_01 .img {
    width: 100% !important;
  }
  .sec_01 .txt01 {
    width: 100% !important;
  }
}

.sec_01 .img {
  width: 48%;
}

.sec_01 .txt01 {
  width: 48%;
}

.sec_02 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .cont_L {
    width: 100% !important;
    margin-bottom: 30px;
    transform: none !important;
  }
  .sec_02 .cont_R {
    width: 100% !important;
    transform: none !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_02 .cont_L {
    transform: translate(0, 80px) !important;
    width: 100% !important;
    padding: 20px !important;
  }
  .sec_02 .cont_R {
    width: 100% !important;
  }
}

.sec_02 .cont_L {
  width: 48%;
  background: #fff;
  padding: 40px;
  transform: translate(-100px, 80px);
}

.sec_02 .cont_R {
  width: 48%;
}

.sec_03 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .cont_L {
    width: 100% !important;
    margin-bottom: 30px;
    transform: none !important;
  }
  .sec_03 .cont_R {
    width: 100% !important;
    transform: none !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_03 .cont_R {
    transform: translate(-5px, -60px) !important;
    width: 100% !important;
  }
  .sec_03 .cont_L {
    width: 100% !important;
    padding: 20px !important;
  }
}

.sec_03 .cont_L {
  width: 48%;
  background: #fff;
  padding: 40px;
  position: relative;
}

.sec_03 .cont_R {
  width: 48%;
  transform: translate(-100px, -60px);
}

.sec_04 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sec_04 .img {
  width: 50%;
}

.sec_05 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .cont_L {
    width: 100% !important;
    margin-bottom: 30px;
  }
  .sec_05 .cont_R {
    width: 100% !important;
  }
}

.sec_05 .cont_L {
  width: 48%;
}

.sec_05 .cont_R {
  width: 48%;
}

.sec_06 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .sec_06 {
    display: block;
  }
  .sec_06 .flxL {
    width: 100% !important;
  }
  .sec_06 .flxR {
    width: 100% !important;
  }
}

.sec_06 .txt02 {
  padding: 10px 0;
}

.sec_06 .flxR {
  width: 40%;
  display: block;
  font-family: source-han-serif-japanese, serif;
}

.sec_06 .flxR .tel2 {
  font-size: 40px;
  background: #aa4a92;
  color: #fff;
  padding: 20px;
}

@media only screen and (max-width: 834px) {
  .sec_06 .flxR .tel2 {
    margin-top: 30px;
  }
}

.sec_06 .flxR .tel2 i {
  font-size: 42px;
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  .sec_06 .flxR .tel2 a {
    color: #fff;
  }
}

.sec_06 .flxR .yoyaku {
  font-size: 20px;
}

.sec_06 .flxR .yoyaku a {
  padding: 15px;
  background: #aa4a92;
  color: #fff;
  display: block;
}

.sec_06 .flxL {
  width: 51%;
}

.news {
  height: 400px;
  overflow-y: scroll;
}

.news dt {
  border: none !important;
  padding: 0 !important;
  display: block !important;
  width: 100% !important;
}

.news dd {
  border: none !important;
  padding: 0 !important;
  width: 100% !important;
}

.news dl {
  border-bottom: 1px dashed #ccc;
  padding: 15px;
  display: block !important;
}

.table_01 {
  border-radius: 15px !important;
  font-size: 18px;
  border: 1px solid #aa4a92;
}

.table_01 td {
  border: none !important;
  border-bottom: 1px solid #c98dba !important;
  border-right: 1px solid #c98dba !important;
  padding: 20px 0 !important;
}

.table_01 .td_topleft {
  border-radius: 15px 0 0 0;
  border-bottom: 1px solid #c98dba !important;
  border-right: 1px solid #c98dba !important;
}

.table_01 .td_btmleft {
  border-radius: 0 0 0 15px;
  border-bottom: 1px solid #c98dba !important;
  border: none !important;
}

.table_01 .td_topright {
  border-radius: 0 15px 0 0;
  border-bottom: 1px solid #c98dba !important;
  border-right: none !important;
}

.table_01 .td_btmright {
  background: #fff;
  border: none !important;
  border-bottom-right-radius: 15px !important;
}

.table_01 .td_center {
  border-bottom: none !important;
}

.table_01 .td_right {
  border-right: none !important;
}

.insta {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
}

.insta .sns_list {
  border: none !important;
  flex-direction: inherit;
}

.insta .sns_photo {
  width: 90% !important;
}

.insta .sns_text {
  display: none;
}

.insta div.sns_list > div {
  border: none !important;
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

/* title */
.h2_04 h2 {
  padding: 10px;
  /*上下 左右の余白*/
  color: #494949;
  /*文字色*/
  background: #f7f6fb;
  border-left: solid 5px #7db4e6;
  /*左線*/
  font-size: 26px;
}

@media only screen and (max-width: 834px) {
  .h2_04 h2 {
    font-size: 20px;
  }
}

.h2_05 h2 {
  border-bottom: 5px solid #7fbfff;
  font-size: 26px;
  padding: 10px;
}

@media only screen and (max-width: 834px) {
  .h2_05 h2 {
    font-size: 20px;
  }
}

.h2_06 h2 {
  border-bottom: 5px solid #7fbfff;
  font-size: 26px;
  padding: 10px;
  width: 100%;
}

@media only screen and (max-width: 834px) {
  .h2_06 h2 {
    font-size: 20px;
  }
}

.h2_06 h2 {
  color: #505050;
  /*文字色*/
  padding: 0.5em;
  /*文字周りの余白*/
  display: inline-block;
  /*おまじない*/
  line-height: 1.3;
  /*行高*/
  background: #dbebf8;
  /*背景色*/
  vertical-align: middle;
  border-radius: 25px 0px 0px 25px;
  /*左側の角を丸く*/
}

.h2_06 h2:before {
  content: '●';
  color: white;
  margin-right: 8px;
}

.h3_01 h3 {
  border-bottom: 1px solid #aa4a92;
  padding: 10px;
}

.h3_02 {
  font-size: 24px;
  padding: 10px;
}

@media only screen and (max-width: 640px) {
  .h3_02 {
    text-align: center !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .h3_02 {
    text-align: center !important;
  }
}

.h3_03 h3 {
  color: #626063;
  font-size: 23px;
  font-weight: bold;
  background: linear-gradient(transparent 70%, #a7d6ff 70%);
}

.h3_04 h3 {
  color: #626063;
  font-size: 23px;
  font-weight: bold;
  background: linear-gradient(transparent 70%, #fff799 70%);
}

.h3_05 h3 {
  color: #626063;
  font-size: 23px;
  font-weight: bold;
  background: linear-gradient(transparent 70%, #c98dba 70%);
  font-family: source-han-serif-japanese, serif;
}

.h3_06 h3 {
  border-bottom: 3px dotted #a7d6ff;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  color: #626063;
  font-family: source-han-serif-japanese, serif;
}

.h3_06 h3 span {
  font-size: 26px;
  color: #16569e;
}

.triangle {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-top: 60px solid #16569e;
}

.sec_07 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_07 {
    display: block;
  }
  .sec_07 .txt {
    width: 100% !important;
  }
  .sec_07 .img {
    width: 100% !important;
  }
}

.sec_07 .txt {
  width: 48%;
}

.sec_07 .img {
  width: 48%;
}

.sec_08 {
  background: #f7f6fb;
  padding: 10px;
}

.sec_09 {
  display: flex;
  justify-content: space-between;
  border: 3px solid #d4d9d6;
  border-radius: 15px;
  padding: 30px;
}

@media only screen and (max-width: 640px) {
  .sec_09 {
    display: block;
  }
  .sec_09 .txt {
    width: 100% !important;
  }
  .sec_09 .img {
    width: 100% !important;
  }
}

.sec_09 .txt {
  width: 48%;
}

.sec_09 .img {
  width: 48%;
}

.sec_09 > div:first-child {
  display: none;
}

.sec_10 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .sec_10 {
    display: block;
  }
  .sec_10 .flxL {
    width: 100% !important;
  }
  .sec_10 .flxR {
    width: 100% !important;
  }
}

.sec_10 .flxL {
  width: 58%;
}

.sec_10 .flxL .txt02 {
  padding: 10px 0;
}

.sec_10 .flxR {
  width: 38%;
}

.sec_11 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_11 {
    display: block;
  }
  .sec_11 .flxL {
    width: 100% !important;
  }
  .sec_11 .flxR {
    width: 100% !important;
  }
  .sec_11 .flxR .txt {
    margin-top: 10px;
  }
}

.sec_11 .flxL {
  width: 38%;
}

.sec_11 .flxR {
  width: 58%;
}

.sec_11 .flxR .tel2 {
  font-size: 28px;
  color: #fff;
  font-family: source-han-serif-japanese, serif;
  background: #aa4a92;
  padding: 15px;
  border-radius: 50px;
}

.sec_11 .flxR .tel2 i {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  .sec_11 .flxR .tel2 a {
    color: #fff;
  }
}

.insta2 .sns_list {
  border: none !important;
}

.insta2 .sns_photo {
  width: 48% !important;
  padding: 20px !important;
}

@media only screen and (max-width: 640px) {
  .insta2 .sns_photo {
    text-align: center !important;
    width: 100% !important;
  }
}

.insta2 .sns_text {
  padding: 20px !important;
}

.insta2 .sns_date {
  font-size: 20px;
  background: #16569e;
  color: #fff !important;
  padding: 10px;
}

.insta2 .caption {
  font-size: 16px;
  padding: 10px 0;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  background: #fff;
}

#anc01 {
  margin-top: -120px;
  padding-top: 120px;
}

#anc02 {
  margin-top: -120px;
  padding-top: 120px;
}

#anc03 {
  margin-top: -100px;
  padding-top: 100px;
}

.gmap iframe {
  width: 100%;
}

.imgbox .box {
  width: 31%;
  margin: 10px;
}

.quewrap .que {
  display: flex;
  align-items: center;
}

.quewrap .answer {
  display: flex;
  align-items: center;
}

.quewrap .que:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #17569f;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  margin-right: 7px;
}

.quewrap .answer:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #aa4a92;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  .table_02 td {
    text-align: center !important;
  }
}

@media only screen and (max-width: 640px) {
  .table_03 dt {
    display: block !important;
    width: 100% !important;
    text-align: center;
  }
  .table_03 dd {
    width: 100% !important;
    text-align: center;
  }
  .table_03 dl {
    display: block !important;
  }
}

.table_04 dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  font-size: 20px;
  border-bottom: 1px dotted #c98dba !important;
}

.table_04 dd {
  width: 100% !important;
  border: none !important;
}

.table_04 dl {
  display: block !important;
}

.anc_btn a {
  color: #fff;
}

.anc_btn i {
  margin-left: 7px;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 70px 0 !important;
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  background-size: cover;
  z-index: -1;
}

.bg_100per_wrap2 {
  position: relative;
  padding: 70px 0 !important;
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap2 {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner2 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont_bg.jpg);
  background-size: cover;
  z-index: -1;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}
